import React, { useRef } from "react"
import styled from "styled-components"
import gsap from "gsap/all"

import {
  Label1,
  Paragraph1,
  Paragraph3,
  Paragraph4,
  Title1,
} from "src/components/Text"
import { BoxOutlineButton } from "src/components/Buttons"

import Colors from "src/styles/Colors"

import { ReactComponent as CircleSVG } from "svg/circles.svg"
import { ReactComponent as GraphSVG } from "svg/greenGraph.svg"
import { ReactComponent as MobileGraphSVG } from "svg/mobileGreenGraph.svg"

import Features from "./Features"
import media from "src/styles/media"

const AccessAnywhere: React.FC<{ data: any }> = ({ data }) => {
  const windowRef: any = useRef()
  const wrapper: any = useRef()

  const parallaxIt = (e, target, movement) => {
    const container = wrapper.current
    const relX = e.pageX - container.offsetLeft
    const relY = e.pageY - container.offsetTop

    gsap.to(target, {
      x:
        ((relX - container.offsetWidth / 2) / container.offsetWidth) * movement,
      y:
        ((relY - container.offsetHeight / 2) / container.offsetHeight) *
        movement,
      ease: "Power2.easeOut",
    })
  }

  const onMove = e => {
    parallaxIt(e, windowRef.current, -50)
    parallaxIt(e, "#graph", -10)
  }

  return (
    <Wrapper ref={wrapper} onMouseMove={onMove}>
      <Graph id="graph" />
      <MobileGraph />
      <TextWrapper>
        <Title>Access your digital goods anywhere</Title>
        <Paragraph>
          Bitski stores and secures your digital assets, allows you to access it
          from any device, and also integrates with the new world of
          decentralized applications.
        </Paragraph>
      </TextWrapper>
      <Window ref={windowRef}>
        <ToolBar>
          <Circles />
          <Label>create a wallet</Label>
        </ToolBar>
        <Form>
          <Title2>Sign Up For A Bitski Account</Title2>
          <Input type="email" placeholder="your@email.com" />
          <Tip>(must be at least 10 characters)</Tip>
          <Input type="password" placeholder="password" />
          <Input type="password" placeholder="confirm password" />
          <Button color={Colors.green} type="submit">
            create account
          </Button>
        </Form>
      </Window>
      <Features data={data} />
    </Wrapper>
  )
}

const Wrapper = styled.section`
  position: relative;
  padding: 0 6.9vw;
  display: flex;
  height: 96.3vw;
  overflow: hidden;
  ${media.tablet} {
    padding: 0 4.8vw;
    display: block;
    height: 637.5vw;
  }
`
const Graph = styled(GraphSVG)`
  position: absolute;
  width: 97.08vw;
  top: -16.3vw;
  left: 21.8vw;
  color: ${Colors.green};
  ${media.tablet} {
    display: none;
  }
`
const MobileGraph = styled(MobileGraphSVG)`
  position: absolute;
  top: 78.7vw;
  right: 0;
  display: none;
  width: 89.4vw;
  color: ${Colors.green};

  ${media.tablet} {
    display: block;
  }
`
const TextWrapper = styled.div`
  position: relative;
  width: 42.6vw;
  padding-top: 18.7vw;
  ${media.tablet} {
    width: 100%;
  }
`
const Title = styled(Title1)`
  color: white;
  ${media.tablet} {
    font-weight: normal;
  }
`
const Paragraph = styled(Paragraph1)`
  width: 33.8vw;
  margin-top: 2vw;
  ${media.tablet} {
    width: 81.2vw;
    margin-top: 7.2vw;
    font-size: 3.9vw;
  }
`
const Window = styled.div`
  position: relative;
  width: 35.7vw;
  height: 34.9vw;
  margin: 13.47vw 0 0 7.8vw;
  background: ${Colors.darkGrey};
  border: solid 1px ${Colors.green};
  border-radius: 0.3vw;
  ${media.tablet} {
    width: 100%;
    margin: 18.1vw 0 0;
    border-radius: 0.98vw;
    height: 120vw;
  }
`
const ToolBar = styled.div`
  height: 2.8vw;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: solid 1px ${Colors.green};
  ${media.tablet} {
    height: 9.7vw;
    justify-content: space-between;
  }
`
const Circles = styled(CircleSVG)`
  margin-left: 0.9vw;
  width: 3.4vw;
  color: ${Colors.green};
  ${media.tablet} {
    width: 11.8vw;
    margin-left: 3.1vw;
  }
`
const Label = styled(Label1)`
  color: ${Colors.green};
  width: 18.6vw;
  text-align: center;
  margin-left: 4.2vw;
  font-size: 1vw;
  letter-spacing: -0.06666666666666667em;
  ${media.tablet} {
    font-size: 3.6vw;
    margin-right: 3.1vw;
  }
`
const Form = styled.form`
  padding: 2vw 7.92vw 0 6.3vw;
  ${media.tablet} {
    padding: 7.2vw 8.4vw 0 7.2vw;
  }
`
const Title2 = styled(Paragraph3)`
  color: white;
  margin-bottom: 2vw;
  ${media.tablet} {
    margin-bottom: 7.2vw;
  }
`
const Input = styled.input`
  width: 21.46vw;
  height: 3.5vw;
  background: ${Colors.charcoal};
  border: none;
  margin-bottom: 0.7vw;
  padding-left: 1.7vw;
  font-size: 1.2vw;
  color: white;
  ::placeholder {
    color: #464646;
  }
  ${media.tablet} {
    width: 100%;
    height: 12.1vw;
    font-size: 4.3vw;
    padding-left: 6vw;
    margin-bottom: 2.4vw;
    border-radius: 0;
  }
`
const Tip = styled(Paragraph4)`
  text-align: center;
  width: 21.46vw;
  color: white;
  margin-top: 2.1vw;
  margin-bottom: 0.7vw;
  ${media.tablet} {
    font-size: 2.4vw;
    width: 100%;
    margin-top: 7.2vw;
    margin-bottom: 2.4vw;
  }
`

const Button = styled(BoxOutlineButton)`
  width: 100%;
  background: transparent;
  justify-content: center;
  margin-top: 2.8vw;
  ${media.tablet} {
    width: 51.6vw;
    height: 12.1vw;
    margin: 10.7vw auto 0;
  }
`
export default AccessAnywhere
